.Tilt {
  background: linear-gradient(89deg, #FF5EDF 0%, #04C8DE 100%);
  border-radius: 20%;
  opacity: 0.8;
  margin-bottom: 5px;
}

@media (max-width: 879px) {
  .Tilt img {
    width: 30px;
  }
}
