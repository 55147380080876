span {
	font-size: 1.1rem;
}

.analyzer-container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	height: 100vh;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	font-size: 1rem;
	font-family: 'Roboto', sans-serif;

}

.predictions-placeholder {
	background-color: #ddd;
	color: #444;
	border: 1px solid rgb(146, 233, 143);
	opacity: 0.8;
	text-align: center;
}

.btn {
	-webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .2), 0 1px 3px 0 rgba(0, 0, 0, .08);
	        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .2), 0 1px 3px 0 rgba(0, 0, 0, .08);
}

.btn:hover {
	-webkit-box-shadow: 0 5px 18px 0 rgba(0, 0, 0, .3), 0 1px 3px 0 rgba(0, 0, 0, .08);
	        box-shadow: 0 5px 18px 0 rgba(0, 0, 0, .3), 0 1px 3px 0 rgba(0, 0, 0, .08);
}

.btn-pick {
	margin: 1rem 0;
}

.imageContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

.image-div {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 70%;
	height: 300px;
	border-radius: .4rem;
	border: 1px solid rgb(126, 246, 13);
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	margin: 0 auto;
}

.button-div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	width: 70%;
	height: 15%;
	min-width: 100%;
	background: radial-gradient(circle farthest-side at 0 50%, #fb1 23.5%, rgba(240, 166, 17, 0) 0)21px 30px,
		radial-gradient(circle farthest-side at 0 50%, #b71 24%, rgba(240, 166, 17, 0) 0)19px 30px,
		linear-gradient(#fb1 14%, rgba(240, 166, 17, 0) 0, rgba(240, 166, 17, 0) 85%, #fb1 0)0 0,
		linear-gradient(150deg, #fb1 24%, #b71 0, #b71 26%, rgba(240, 166, 17, 0) 0, rgba(240, 166, 17, 0) 74%, #b71 0, #b71 76%, #fb1 0)0 0,
		linear-gradient(30deg, #fb1 24%, #b71 0, #b71 26%, rgba(240, 166, 17, 0) 0, rgba(240, 166, 17, 0) 74%, #b71 0, #b71 76%, #fb1 0)0 0,
		linear-gradient(90deg, #b71 2%, #fb1 0, #fb1 98%, #b71 0)0 0 #fb1;
	background-size: 40px 60px;
	border-radius: .5rem;
}

.classifier-title {
	margin-top: 10px;
	font-size: 2rem;
	color: white;
}

.tf-img {
	height: 100px;
	width: auto;
	opacity: 0;
	display: none;
}

.disclaimer p {
	margin: 20px auto;
	line-height: 0.5rem;
}

@media (max-width: 879px) {
	.disclaimer p {
		line-height: normal;
	}
	.classifier-title {
		font-size: 1.5rem;
	}
}

@media (max-width: 459px) {
	.classifier-title {
		margin-top: 55%;
		font-size: 1rem;
	}

	.button-div {
		width: 20%;
		height: 10%;
	}

		.button-div button {
			width: -webkit-fit-content;
			width: -moz-fit-content;
			width: fit-content;
		}

	.button-div button span,
	.button-div button svg {
		font-size: 0.8rem;
	}
}


button {
	background: #a463f2;
	color: #fff;
	font-family: Sans-serif;
	font-size: 1.25rem;
	font-weight: 400;
	height: 60px;
	width: 25%;
	line-height: 1.15;
	margin: 25px 25px;
	text-align: center;
	cursor: pointer;
	border: 1px solid rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
	-webkit-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

button:hover {
	background: #CF4647;
}

.particles {
	font-family: "Courier New",
		Courier,
		monospace,
		sans-serif;
	background: -webkit-gradient(linear, left top, right top, from(#009fff), to(#ec2f4b));
	background: linear-gradient(to right, #009fff, #ec2f4b);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	height: 100vh;
	position: fixed;
	top: 0;
	right: 15%;
	bottom: 8%;
	left: 15%;
	z-index: -1;
}

.modal {
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);

	.modal-main {
		position: fixed;
		background: white;
		width: 80%;
		height: auto;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
	}
}

.display-block {
	display: block;
}

.display-none {
	display: none;
}
