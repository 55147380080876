/* Custom Stylesheet */
/**
 * Use this file to override Materialize files so you can update
 * the core Materialize files in the future
 *
 * Made By MaterializeCSS.com
 */

nav ul a,
nav .brand-logo {
  color: #444;
}

p {
  line-height: 2rem;
}

.sidenav-trigger {
  color: #26a69a;
}

.parallax-container {
  min-height: 380px;
  line-height: 0;
  height: auto;
  color: rgba(255, 255, 255, .9);
}

.parallax-container .section {
  width: 100%;
}

@media only screen and (max-width : 992px) {
  .parallax-container .section {
    position: absolute;
    top: 40%;
  }

  #index-banner .section {
    top: 10%;
  }
}

@media only screen and (max-width : 600px) {
  #index-banner .section {
    top: 0;
  }
}

.icon-block {
  padding: 0 15px;
}

.icon-block .material-icons {
  font-size: inherit;
}

footer.page-footer {
  margin: 0;
}


/* Social Icons */
.footer-social-icons {
  width: 350px;
  display: block;
  margin: 0 auto;
}

.social-icon {
  color: #fff;
}

ul.social-icons {
  margin-top: 10px;
}

.social-icons li {
  vertical-align: top;
  display: inline;
}

.social-icons a {
  color: #000;
  text-decoration: none;
}

.fa {
  border-radius: 50%;
}

.fa-facebook {
  padding: 10px 14px;
  transition: .5s;
}

.fa-facebook:hover {
  color: #fff;
  background-color: #3d5b99;
}

.fa-twitter {
  padding: 10px 12px;
  transition: .5s;
}

.fa-twitter:hover {
  color: #fff;
  background-color: #00aced;
}

.fa-rss {
  padding: 10px 14px;
  transition: .5s;
}

.fa-rss:hover {
  color: #fff;
  background-color: #eb8231;
}

.fa-youtube {
  padding: 10px 14px;
  transition: .5s;
}

.fa-youtube:hover {
  color: #fff;
  background-color: #e64a41;
}

.fa-linkedin {
  padding: 10px 14px;
  transition: .5s;
}

.fa-linkedin:hover {
  color: #fff;
  background-color: #0073a4;
}

.fa-github {
  padding: 10px 14px;
  transition: .5s;
}

.fa-github:hover {
  color: #fff;
  background-color: #5a32a3;
}
