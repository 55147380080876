.predictions-container {
  margin: 0 auto;
  width: 70%;
  opacity: 0.8;
  font-weight: 100;

  ol {
    counter-reset: li;
    list-style: none;
    *list-style: decimal;
    font: 1.1rem 'trebuchet MS', 'lucida sans';
    padding: 0;
    margin-bottom: 4em;
    text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
  }

  ol ol {
    margin: 0 0 0 2em;
  }

  .rectangle-list {
    height: 40%;
    margin-bottom: 10%;
    a {
      position: relative;
      display: block;
      padding: .4em .4em .4em .8em;
      *padding: .4em;
      margin: .5em 0 .5em 2.5em;
      background: #ddd;
      color: #444;
      text-decoration: none;
      -webkit-transition: all .3s ease-out;
      transition: all .3s ease-out;
      border: 0.8px solid white;
    }

    a:hover {
      background: #eee;
    }

    a:before {
      content: counter(li);
      counter-increment: li;
      position: absolute;
      left: -2.5em;
      top: 50%;
      margin-top: -1em;
      background: #bcffc7;
      height: 2em;
      width: 2em;
      line-height: 2em;
      text-align: center;
      font-weight: bold;
    }

    a:after {
      position: absolute;
      content: '';
      border: .5em solid transparent;
      left: -1em;
      top: 50%;
      margin-top: -.5em;
      -webkit-transition: all .3s ease-out;
      transition: all .3s ease-out;
    }

    a:hover:after {
      left: -.5em;
      border-left-color: #bcffc7;
    }
  }
}
