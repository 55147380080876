.video-container {
  margin-top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;

  video {
      width: 540px;
      height: 380px;
      margin: 0;
      padding: 0;
  }

  canvas {
    position: fixed;
    top: -300px;
    left: 30%;
  }

  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 40%;
    height: 30%;
  }
}
