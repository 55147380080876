.realtime-container {
  display: block;
  width: 50%;
  height: 40%;

  .realtime-video {
    position: fixed;
    top: -300px;
    left: 30%;
  }
}


