/* Custom Stylesheet */
/**
 * Use this file to override Materialize files so you can update
 * the core Materialize files in the future
 *
 * Made By MaterializeCSS.com
 */

nav ul a,
nav .brand-logo {
  color: #444;
}

p {
  line-height: 2rem;
}

.sidenav-trigger {
  color: #26a69a;
}

.parallax-container {
  min-height: 380px;
  line-height: 0;
  height: auto;
  color: rgba(255, 255, 255, .9);
}

.parallax-container .section {
  width: 100%;
}

@media only screen and (max-width : 992px) {
  .parallax-container .section {
    position: absolute;
    top: 40%;
  }

  #index-banner .section {
    top: 10%;
  }
}

@media only screen and (max-width : 600px) {
  #index-banner .section {
    top: 0;
  }
}

.icon-block {
  padding: 0 15px;
}

.icon-block .material-icons {
  font-size: inherit;
}

footer.page-footer {
  margin: 0;
}


/* Social Icons */
.footer-social-icons {
  width: 350px;
  display: block;
  margin: 0 auto;
}

.social-icon {
  color: #fff;
}

ul.social-icons {
  margin-top: 10px;
}

.social-icons li {
  vertical-align: top;
  display: inline;
}

.social-icons a {
  color: #000;
  text-decoration: none;
}

.fa {
  border-radius: 50%;
}

.fa-facebook {
  padding: 10px 14px;
  transition: .5s;
}

.fa-facebook:hover {
  color: #fff;
  background-color: #3d5b99;
}

.fa-twitter {
  padding: 10px 12px;
  transition: .5s;
}

.fa-twitter:hover {
  color: #fff;
  background-color: #00aced;
}

.fa-rss {
  padding: 10px 14px;
  transition: .5s;
}

.fa-rss:hover {
  color: #fff;
  background-color: #eb8231;
}

.fa-youtube {
  padding: 10px 14px;
  transition: .5s;
}

.fa-youtube:hover {
  color: #fff;
  background-color: #e64a41;
}

.fa-linkedin {
  padding: 10px 14px;
  transition: .5s;
}

.fa-linkedin:hover {
  color: #fff;
  background-color: #0073a4;
}

.fa-github {
  padding: 10px 14px;
  transition: .5s;
}

.fa-github:hover {
  color: #fff;
  background-color: #5a32a3;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

span {
	font-size: 1.1rem;
}

.analyzer-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	flex-direction: column;
	font-size: 1rem;
	font-family: 'Roboto', sans-serif;

}

.predictions-placeholder {
	background-color: #ddd;
	color: #444;
	border: 1px solid rgb(146, 233, 143);
	opacity: 0.8;
	text-align: center;
}

.btn {
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .2), 0 1px 3px 0 rgba(0, 0, 0, .08);
}

.btn:hover {
	box-shadow: 0 5px 18px 0 rgba(0, 0, 0, .3), 0 1px 3px 0 rgba(0, 0, 0, .08);
}

.btn-pick {
	margin: 1rem 0;
}

.imageContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.image-div {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 70%;
	height: 300px;
	border-radius: .4rem;
	border: 1px solid rgb(126, 246, 13);
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
}

.button-div {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 70%;
	height: 15%;
	min-width: 100%;
	background: radial-gradient(circle farthest-side at 0 50%, #fb1 23.5%, rgba(240, 166, 17, 0) 0)21px 30px,
		radial-gradient(circle farthest-side at 0 50%, #b71 24%, rgba(240, 166, 17, 0) 0)19px 30px,
		linear-gradient(#fb1 14%, rgba(240, 166, 17, 0) 0, rgba(240, 166, 17, 0) 85%, #fb1 0)0 0,
		linear-gradient(150deg, #fb1 24%, #b71 0, #b71 26%, rgba(240, 166, 17, 0) 0, rgba(240, 166, 17, 0) 74%, #b71 0, #b71 76%, #fb1 0)0 0,
		linear-gradient(30deg, #fb1 24%, #b71 0, #b71 26%, rgba(240, 166, 17, 0) 0, rgba(240, 166, 17, 0) 74%, #b71 0, #b71 76%, #fb1 0)0 0,
		linear-gradient(90deg, #b71 2%, #fb1 0, #fb1 98%, #b71 0)0 0 #fb1;
	background-size: 40px 60px;
	border-radius: .5rem;
}

.classifier-title {
	margin-top: 10px;
	font-size: 2rem;
	color: white;
}

.tf-img {
	height: 100px;
	width: auto;
	opacity: 0;
	display: none;
}

.disclaimer p {
	margin: 20px auto;
	line-height: 0.5rem;
}

@media (max-width: 879px) {
	.disclaimer p {
		line-height: normal;
	}
	.classifier-title {
		font-size: 1.5rem;
	}
}

@media (max-width: 459px) {
	.classifier-title {
		margin-top: 55%;
		font-size: 1rem;
	}

	.button-div {
		width: 20%;
		height: 10%;
	}

		.button-div button {
			width: -webkit-fit-content;
			width: -moz-fit-content;
			width: fit-content;
		}

	.button-div button span,
	.button-div button svg {
		font-size: 0.8rem;
	}
}


button {
	background: #a463f2;
	color: #fff;
	font-family: Sans-serif;
	font-size: 1.25rem;
	font-weight: 400;
	height: 60px;
	width: 25%;
	line-height: 1.15;
	margin: 25px 25px;
	text-align: center;
	cursor: pointer;
	border: 1px solid rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
	transition: all 0.3s ease 0s;
}

button:hover {
	background: #CF4647;
}

.particles {
	font-family: "Courier New",
		Courier,
		monospace,
		sans-serif;
	background: linear-gradient(to right, #009fff, #ec2f4b);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	height: 100vh;
	position: fixed;
	top: 0;
	right: 15%;
	bottom: 8%;
	left: 15%;
	z-index: -1;
}

.modal {
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);

	.modal-main {
		position: fixed;
		background: white;
		width: 80%;
		height: auto;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.display-block {
	display: block;
}

.display-none {
	display: none;
}

.spinner-container {
	opacity: 0.8;
}

.spinner-div {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	border-radius: .3rem;
}

.spinner {
	border: 7px solid #fff;
	border-top: 7px solid #007bff;
	height: 4rem;
	width: 4rem;
	border-radius: 50%;
	-webkit-animation: 1s infiniteRotation linear infinite;
	        animation: 1s infiniteRotation linear infinite;
}

@-webkit-keyframes infiniteRotation {
	100%{
		transform: rotate(360deg);
	}
}

@keyframes infiniteRotation {
	100%{
		transform: rotate(360deg);
	}
}

.loader-message {
	font-size: 1rem;
	margin-top: 1rem;
	color: white;
	font-weight: bold;
}

.video-container {
  margin-top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column; }
  .video-container video {
    width: 540px;
    height: 380px;
    margin: 0;
    padding: 0; }
  .video-container canvas {
    position: fixed;
    top: -300px;
    left: 30%; }
  .video-container .buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 40%;
    height: 30%; }

.realtime-container {
  display: block;
  width: 50%;
  height: 40%; }
  .realtime-container .realtime-video {
    position: fixed;
    top: -300px;
    left: 30%; }

.predictions-container {
  margin: 0 auto;
  width: 70%;
  opacity: 0.8;
  font-weight: 100; }
  .predictions-container ol {
    counter-reset: li;
    list-style: none;
    *list-style: decimal;
    font: 1.1rem 'trebuchet MS', 'lucida sans';
    padding: 0;
    margin-bottom: 4em;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5); }
  .predictions-container ol ol {
    margin: 0 0 0 2em; }
  .predictions-container .rectangle-list {
    height: 40%;
    margin-bottom: 10%; }
    .predictions-container .rectangle-list a {
      position: relative;
      display: block;
      padding: .4em .4em .4em .8em;
      *padding: .4em;
      margin: .5em 0 .5em 2.5em;
      background: #ddd;
      color: #444;
      text-decoration: none;
      transition: all .3s ease-out;
      border: 0.8px solid white; }
    .predictions-container .rectangle-list a:hover {
      background: #eee; }
    .predictions-container .rectangle-list a:before {
      content: counter(li);
      counter-increment: li;
      position: absolute;
      left: -2.5em;
      top: 50%;
      margin-top: -1em;
      background: #bcffc7;
      height: 2em;
      width: 2em;
      line-height: 2em;
      text-align: center;
      font-weight: bold; }
    .predictions-container .rectangle-list a:after {
      position: absolute;
      content: '';
      border: .5em solid transparent;
      left: -1em;
      top: 50%;
      margin-top: -.5em;
      transition: all .3s ease-out; }
    .predictions-container .rectangle-list a:hover:after {
      left: -.5em;
      border-left-color: #bcffc7; }

.modal {
  margin: auto;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); }
  .modal .modal-main {
    position: fixed;
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .modal .display-block {
    display: block; }
  .modal .display-none {
    display: none; }

.Tilt {
  background: linear-gradient(89deg, #FF5EDF 0%, #04C8DE 100%);
  border-radius: 20%;
  opacity: 0.8;
  margin-bottom: 5px;
}

@media (max-width: 879px) {
  .Tilt img {
    width: 30px;
  }
}

.StripeCheckout {
  width: 30%;
}


.talk-bubble {
  display: inline-block;
  position: relative;
	height: auto;
	background-color: #F2F2F2;
}

.round{
  border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;

}

.tri-right.border.left-top:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: -40px;
	right: auto;
  top: -8px;
	bottom: auto;
	border: 32px solid;
    border-color: #666 transparent transparent transparent;
        box-shadow: -2px 2px 2px 0 rgba( 178, 178, 178, .4 );
        transform:             rotate( 29deg ) skew( -35deg );
        -moz-transform:    rotate( 29deg ) skew( -35deg );
        -ms-transform:     rotate( 29deg ) skew( -35deg );
        -o-transform:      rotate( 29deg ) skew( -35deg );
        -webkit-transform: rotate( 29deg ) skew( -35deg );
                    box-shadow: -2px 2px 2px 0 rgba( 178, 178, 178, .4 );


}

.tri-right.left-top:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: -20px;
	right: auto;
  top: 0px;
	bottom: auto;
	border: 22px solid;
	border-color: #F2F2F2 transparent transparent transparent;
}

.tri-right.border.left-in:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: -40px;
	right: auto;
  top: 30px;
	bottom: auto;
	border: 20px solid;
    border-color: #666 #666 transparent transparent;
      transform:             rotate( 29deg ) skew( -35deg );
        -moz-transform:    rotate( 29deg ) skew( -35deg );
        -ms-transform:     rotate( 29deg ) skew( -35deg );
        -o-transform:      rotate( 29deg ) skew( -35deg );
        -webkit-transform: rotate( 29deg ) skew( -35deg );
            box-shadow: -2px 2px 2px 0 rgba( 178, 178, 178, .4 );

}

.tri-right.left-in:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: -20px;
	right: auto;
  top: 38px;
	bottom: auto;
	border: 12px solid;
	border-color: #F2F2F2 #F2F2F2 transparent transparent;
}

.tri-right.border.btm-left:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: -8px;
  right: auto;
  top: auto;
	bottom: -40px;
	border: 32px solid;
    border-color: transparent transparent transparent #666;
      transform:             rotate( 29deg ) skew( -35deg );
        -moz-transform:    rotate( 29deg ) skew( -35deg );
        -ms-transform:     rotate( 29deg ) skew( -35deg );
        -o-transform:      rotate( 29deg ) skew( -35deg );
        -webkit-transform: rotate( 29deg ) skew( -35deg );
                    box-shadow: -2px 2px 2px 0 rgba( 178, 178, 178, .4 );

}

.tri-right.btm-left:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: 0px;
  right: auto;
  top: auto;
	bottom: -20px;
	border: 22px solid;
	border-color: transparent transparent transparent #F2F2F2;
}

.tri-right.border.btm-left-in:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: 30px;
  right: auto;
  top: auto;
	bottom: -40px;
	border: 20px solid;
    border-color: #666 transparent transparent #666;
      transform:             rotate( 29deg ) skew( -35deg );
        -moz-transform:    rotate( 29deg ) skew( -35deg );
        -ms-transform:     rotate( 29deg ) skew( -35deg );
        -o-transform:      rotate( 29deg ) skew( -35deg );
        -webkit-transform: rotate( 29deg ) skew( -35deg );
                    box-shadow: -2px 2px 2px 0 rgba( 178, 178, 178, .4 );

}

.tri-right.btm-left-in:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: 38px;
  right: auto;
  top: auto;
	bottom: -20px;
	border: 12px solid;
	border-color: #F2F2F2 transparent transparent #F2F2F2;
}

.tri-right.border.btm-right-in:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: 30px;
	bottom: -40px;
	border: 20px solid;
    border-color: #666 #666 transparent transparent;
      transform:             rotate( 29deg ) skew( -35deg );
        -moz-transform:    rotate( 29deg ) skew( -35deg );
        -ms-transform:     rotate( 29deg ) skew( -35deg );
        -o-transform:      rotate( 29deg ) skew( -35deg );
        -webkit-transform: rotate( 29deg ) skew( -35deg );
                    box-shadow: -2px 2px 2px 0 rgba( 178, 178, 178, .4 );

}

.tri-right.btm-right-in:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: 38px;
	bottom: -20px;
	border: 12px solid;
	border-color: #F2F2F2 #F2F2F2 transparent transparent;
}

.tri-right.border.btm-right:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: -8px;
	bottom: -40px;
	border: 20px solid;
    border-color: #666 #666 transparent transparent;
      transform:             rotate( 29deg ) skew( -35deg );
        -moz-transform:    rotate( 29deg ) skew( -35deg );
        -ms-transform:     rotate( 29deg ) skew( -35deg );
        -o-transform:      rotate( 29deg ) skew( -35deg );
        -webkit-transform: rotate( 29deg ) skew( -35deg );
                    box-shadow: -2px 2px 2px 0 rgba( 178, 178, 178, .4 );

}

.tri-right.btm-right:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: 0px;
	bottom: -20px;
	border: 12px solid;
	border-color: #F2F2F2 #F2F2F2 transparent transparent;
}

.tri-right.border.right-in:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: -40px;
  top: 30px;
	bottom: auto;
	border: 20px solid;
    border-color: #666 transparent transparent #666;
      transform:             rotate( 29deg ) skew( -35deg );
        -moz-transform:    rotate( 29deg ) skew( -35deg );
        -ms-transform:     rotate( 29deg ) skew( -35deg );
        -o-transform:      rotate( 29deg ) skew( -35deg );
        -webkit-transform: rotate( 29deg ) skew( -35deg );
                    box-shadow: -2px 2px 2px 0 rgba( 178, 178, 178, .4 );

}

.tri-right.right-in:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: -20px;
  top: 38px;
	bottom: auto;
	border: 12px solid;
	border-color: #F2F2F2 transparent transparent #F2F2F2;
}

.tri-right.border.right-top:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: -40px;
  top: -8px;
	bottom: auto;
	border: 32px solid;
    border-color: #666 transparent transparent transparent;
      transform:             rotate( 29deg ) skew( -35deg );
        -moz-transform:    rotate( 29deg ) skew( -35deg );
        -ms-transform:     rotate( 29deg ) skew( -35deg );
        -o-transform:      rotate( 29deg ) skew( -35deg );
        -webkit-transform: rotate( 29deg ) skew( -35deg );
                    box-shadow: -2px 2px 2px 0 rgba( 178, 178, 178, .4 );

}

.tri-right.right-top:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: -20px;
  top: 0px;
	bottom: auto;
	border: 20px solid;
	border-color: #F2F2F2 transparent transparent transparent;
}

.talktext{
  padding: 1em;
	text-align: left;
  line-height: 1.5em;
}

.talktext p{
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

.typing-indicator {
  background-color: #F2F2F2;
  will-change: transform;
  width: auto;
  border-radius: 50px;
  padding: 20px;
  display: table;
  margin: 0 auto;
  position: relative;
  -webkit-animation: 2s bulge infinite ease-out;
          animation: 2s bulge infinite ease-out;
}

.typing-indicator::before, .typing-indicator::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: -2px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #E6E7ED;
}

.typing-indicator::after {
  height: 10px;
  width: 10px;
  left: -10px;
  bottom: -10px;
}

.typing-indicator span {
  height: 15px;
  width: 15px;
  float: left;
  margin: 0 1px;
  background-color: #9E9EA1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}

.typing-indicator span:nth-of-type(1) {
  -webkit-animation: 1s blink infinite 0.3333s;
          animation: 1s blink infinite 0.3333s;
}

.typing-indicator span:nth-of-type(2) {
  -webkit-animation: 1s blink infinite 0.6666s;
          animation: 1s blink infinite 0.6666s;
}

.typing-indicator span:nth-of-type(3) {
  -webkit-animation: 1s blink infinite 0.9999s;
          animation: 1s blink infinite 0.9999s;
}

@-webkit-keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}

@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}

html {
  height: 100%;
  width: 100%;
}

.App {
    width: 100%;
    margin: 0 auto;
    padding: 0;
}

